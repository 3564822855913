// User profile
import About from "../pages/About";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Blogs from "../pages/Blogs";
import BlogDetail from "../pages/Blogs/BlogDetail";
import MobileApp from "../pages/Services/MobileApp";
import WebDesign from "../pages/Services/WebDesign";
import DigitalMarketing from "../pages/Services/DigitalMarketing";
import MediaServices from "../pages/Services/MediaServices";
import EnterpriseApplications from "../pages/Services/EnterpriseApplications";
import Strategy from "../pages/Services/Strategy";
import Work from "../pages/Services/Work";
import PrivacyPolicy from "../pages/OtherPages/PrivacyPolicy";
import TermsOfUse from "../pages/OtherPages/TermsOfUse";

const authProtectedRoutes = [
//   { path: "/dashboard", exact: true, component: Dashboard },
//   //Partners
//   { path: "/patners/:type/:id", exact: true, component: Patners },
//   { path: "/patners/:type", exact: true, component: Patners },
//   { path: "/patners", exact: true, component: Patners },
//   //Products
//   { path: "/products/:type/:id", exact: true, component: Products },
//   { path: "/products/:type", exact: true, component: Products },
//   { path: "/products", exact: true, component: Products },
//   //Invoice
//   { path: "/invoice", exact: true, component: Invoice },
//   { path: "/invoice-create", exact: true, component: InvoiceCreate },

//   //Reports
//   { path: "/reports/:id", exact: true, component: Reports },
//   //Support
//   { path: "/support/:type/:id", exact: true, component: Support },
//   { path: "/support/:type", exact: true, component: Support },
//   { path: "/support", exact: true, component: Support },
//   { path: "/inbox", exact: true, component: Inbox },
//   { path: "/inbox/:id", exact: true, component: Inbox },

//   //profile
//   { path: "/profile", component: UserProfile },
//   { path: "/change-password", component: ChangePassword },

//   // this route should be at the end of all other routes
//   // eslint-disable-next-line react/display-name
//   { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/about-us", component: About },
  { path: "/contact-us", component: Contact },
  { path: "/blogs", component: Blogs },
  { path: "/blog-detail", component: BlogDetail },
  { path: "/mobile-app-developemnt", component: MobileApp },
  { path: "/web-design", component: WebDesign },
  { path: "/digital-marketing-services", component: DigitalMarketing },
  { path: "/media-services", component: MediaServices },
  { path: "/enterprise-applications", component: EnterpriseApplications },
  { path: "/strategy", component: Strategy },
  { path: "/work", component: Work },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-use", component: TermsOfUse },
  { path: "/", component: Home },
];

export { authProtectedRoutes, publicRoutes };
