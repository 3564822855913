import React from "react";

const TermsOfUse=(props)=>{
    return (<>
        <section id="hero" className="about_banner otherpage-hero">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-7 align-self-center">
                        <div className="hero-container" data-aos="fade-left" data-aos-delay="0">
                            <h1>Terms of Use</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <main id="main">
            <div className="otherpage">
                <div className="container" data-aos="fade-up">
                    <section className="section section-top">
                        <div className="row d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
                            {/* <div className="">
                                <div className="">
                                    <h2 className="pe-sm-5">Privacy Policy</h2>
                                </div>
                            </div> */}
                            <div className="">
                                <div className="">
                                    <p className="text-left mb-5">Welcome to the website of Adciasis Solutions ("Company," "we," "us," or "our"). These
Terms of Use ("Terms") govern your use of our website (the "Website"). By accessing or
using the Website, you signify that you have read, understood, and agree to be bound by
these Terms. If you do not agree to these Terms, you may not use the Website.
</p>
                                    
                                   
                                    <ol className="mb-5 ">
                                        <li>Website Content and Intellectual Property
                                            <ul>
                                                <li>(a) All content available on the Website, including but not limited to text, graphics,
                                                logos, images, videos, software, and any other materials, is the property of the
                                                Company or its licensors and is protected by intellectual property laws.
                                                </li>
                                                <li>(b) You may access and view the content on the Website solely for your personal,
                                                non-commercial use. Any unauthorized use, reproduction, modification,
                                                distribution, display, transmission, or creation of derivative works based on the
                                                Website content is strictly prohibited.</li>
                                            </ul>
                                        </li>
                                        <li>User Conduct
                                            <ul>
                                                <li>(a) You agree to use the Website only for lawful purposes and in a manner that
does not infringe or violate the rights of others or restrict or inhibit their use and
enjoyment of the Website.
</li>
                                                <li>(b) You shall not engage in any conduct that could damage, disable, overburden,
or impair the Website or interfere with any other party's use and enjoyment of the
Website.</li>
                                                <li>(c) You agree not to upload, post, transmit, or otherwise make available any
content that is harmful, unlawful, defamatory, obscene, offensive, or infringing on
intellectual property rights.</li>
                                            </ul>
                                        </li>
                                        <li>Third-Party Websites and Content
                                            <ul>
                                                <li>(a) The Website may contain links to third-party websites or resources that are
not owned or controlled by the Company. We do not endorse, control, or assume
any responsibility for the content or practices of these third-party websites.
</li>
                                                <li>(b) Your interactions with third-party websites, including the use of any content,
products, or services, are solely between you and the applicable third party. You
acknowledge and agree that the Company shall not be responsible or liable,
directly or indirectly, for any loss or damage caused or alleged to be caused by or
in connection with the use of or reliance on any such content, products, or
services available on or through any third-party website.
</li>
                                            </ul>
                                        </li>
                                        <li>Disclaimer of Warranties
                                            <ul>
                                                <li>(a) The Website and its content are provided on an "as-is" and "as available" basis
without any warranties of any kind, whether express or implied.
</li>
                                                <li>(b) The Company does not warrant that the Website will be error-free,
uninterrupted, secure, or free of viruses or other harmful components. You
acknowledge that you use the Website at your own discretion and risk.
</li>
                                            </ul>
                                        </li>
                                        <li>Limitation of Liability
                                            <ul>
                                                <li>(a) To the fullest extent permitted by applicable law, the Company, its affiliates,
and their respective directors, officers, employees, or agents shall not be liable
for any direct, indirect, incidental, special, or consequential damages arising out
of or in connection with the use of the Website.
</li>
                                                <li>(b) This limitation of liability applies to any damages or losses resulting from
errors, mistakes, omissions, interruptions, defects, delays in operation, or any
other website performance issues.
</li>
                                            </ul>
                                        </li>
                                        <li>Privacy
                                            <ul>
                                                <li>(a) Your use of the Website is subject to our Privacy Policy, which is incorporated
by reference into these Terms. Please review our Privacy Policy to understand our
practices concerning the collection, use, and disclosure of personal information.
</li>
                                            </ul>
                                        </li>
                                        <li>Changes to the Terms
                                            <ul>
                                                <li>(a) We reserve the right to modify or update these Terms at any time without
prior notice. By continuing to use the Website after any changes, you agree to be
bound by the revised Terms.
</li>
                                            </ul>
                                        </li>
                                        <li>Governing Law and Jurisdiction
                                            <ul>
                                                <li>(a) These Terms shall be governed by and construed in accordance with the laws
of India. Any disputes arising out of or relating to these Terms or your use of the
Website shall be exclusively resolved in the courts of New Delhi, India.
</li>
                                            </ul>
                                        </li>
                                        <li>Severability and Waiver
                                            <ul>
                                                <li>(a) If any provision of these Terms is found to be invalid or unenforceable, the
remaining provisions shall remain in full force and effect.
</li>
                                                <li>(b) The failure of the Company to enforce any right or provision of these Terms
shall not be deemed a waiver of such right or provision.
</li>
                                            </ul>
                                        </li>
                                        
                                        <li>Entire Agreement
                                            <ul>
                                                <li>(a) These Terms constitute the entire agreement between you and the Company
regarding your use of the Website and supersede all prior and contemporaneous
agreements, understandings, or representations.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    
                                    <p className="mb-5">
                                    If you have any questions or concerns about these Terms, please contact us at <a href="mailto:contact@adciasis.com">contact@adciasis.com</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </>
    );
}
export default TermsOfUse;