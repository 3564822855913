import React from "react";

const Strategy = (props) => {
	return (
		<>
			<section id="hero" className="strategy_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>Strategy</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section section-top">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Digital Strategy</h2>
									<p className="text-left mb-5">Adciasis’ digital strategy services are designed to help brands develop and implement a comprehensive digital strategy that drives growth, engages customers, and delivers results. Our team of digital strategy experts has years of experience helping brands develop and implement successful digital strategies. We work closely with our clients to understand their unique business needs and develop customized solutions that deliver results.</p>
									<p className="text-left mb-5">At Adciasis, we pride ourselves on delivering measurable results and creating long-lasting relationships with our clients. Our team is committed to your success, and we will work tirelessly to help you achieve your goals.</p>
									<p className="mb-0">Our services include market analysis, competitor research, and customer segmentation to help you understand your target audience and position your business for growth. We also offer strategic planning and implementation support to help you execute your vision.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section light-bg my-5">
					<div className="container py-5 my-3">
						<div className="row">
							<div className="col-md-6 col-sm-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<img alt="Strategy Consulting Services" className="img-fluid" src="assets/img/strategy-side-banner.jpg" />
								</div>
							</div>
							<div className="col-md-6 col-sm-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="ps-sm-3 ps-sm-2">
									<h2 className="pe-sm-5">With our Strategy Consulting Services, you can:</h2>
									<ul className="ic-check-list">
										<li>Identify new revenue streams and growth opportunities</li>
										<li>Improve operational efficiency and reduce costs</li>
										<li>Optimize your marketing and sales efforts</li>
										<li>Enhance your customer experience and satisfaction</li>
										<li>Develop a clear, actionable roadmap for success</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="text-center">
									<h2>Why Brands need Digital Strategy</h2>
									<p className="text-left mb-4">In today's fast-paced digital world, brands that do not have a comprehensive digital strategy in place risk falling behind their competitors. Here are some of the reasons why brands need digital strategy:</p>
								</div>
							</div>
						</div>
						<div className="digital-srategy-section">
							<div className="row">
								<div className="col-md-4 col-sm-4 mt-5" data-aos="fade-up" data-aos-delay="0">
									<div className="card-plain">
										<div className="mb-2">
											<img alt="" className="w-100" src="assets/img/online-presence.png" />
										</div>
										<div className="content">
											<h3>Increased Online Presence</h3>
											<p className="text-left mb-3">With more people spending time online than ever before, having a strong online presence is crucial. A digital strategy helps brands reach their target audience through various digital channels such as social media, search engines, and mobile apps.</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 px-sm-4 mt-5" data-aos="fade-up" data-aos-delay="0">
									<div className="card-plain">
										<div className="mb-2">
											<img alt="" className="w-100" src="assets/img/customer-engagement.png" />
										</div>
										<div className="content">
											<h3>Improved Customer Engagement</h3>
											<p className="text-left mb-3">A digital strategy helps brands engage with their customers through various touchpoints, including email marketing, social media, and mobile apps. This helps build brand loyalty and improve customer retention.</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 mt-5" data-aos="fade-up" data-aos-delay="0">
									<div className="card-plain">
										<div className="mb-2">
											<img alt="" className="w-100" src="assets/img/competitive-advantage.png" />
										</div>
										<div className="content">
											<h3>
												Competitive <br />
												Advantage
											</h3>
											<p className="text-left mb-3">Brands that have a well-defined digital strategy can gain a competitive advantage by reaching new customers and retaining existing ones. This can lead to increased sales and revenue growth.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="one-stop_section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-11" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
				<section className="section">
					<div className="container mt-3 mb-4">
						<div className="row offer-points">
							<div className="col-md-12 col-sm-12 d-flex mb-5" data-aos="fade-up" data-aos-delay="0">
								<h2 className="pe-sm-5 text-center">Digital Strategy Services we offer</h2>
							</div>
							<div className="row align-items-stretch mb-5" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-1">
									<div className="sno sno1">1</div>
								</div>
								<div className="col-sm-11">
									<h4>Sketching Actionable Digital Roadmaps</h4>
									<p>Recruiters are spending too long on manual repetitive tasks and often trying to co-ordinate far to many disparate tools, leaving less time to support and engage with customers.</p>
								</div>
							</div>
							<div className="row align-items-stretch mb-5" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-1">
									<div className="sno sno2">2</div>
								</div>
								<div className="col-sm-11">
									<h4>Constructing and Designing New Commercial Websites</h4>
									<p>A brand's website is often the first point of contact with potential customers. Our team designs and develops new business websites that are optimized for search engines, mobile-friendly, and provide an excellent user experience.</p>
								</div>
							</div>
							<div className="row align-items-stretch mb-5" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-1">
									<div className="sno sno3">3</div>
								</div>
								<div className="col-sm-11">
									<h4>Creating Native and Hybrid Mobile Applications</h4>
									<p>Mobile apps are a crucial part of a brand's digital strategy. Our team creates hybrid and native mobile apps that provide an immersive user experience, drive engagement, and increase brand loyalty.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default Strategy;
