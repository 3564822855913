import React, { useState } from "react";

const About = (props) => {
	const [selectedTab, setSelectedTab] = useState(1);
	return (
		<>
			<section id="hero" className="about_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>About Us</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<main id="main">
				<div className="">
					<div className="container" data-aos="fade-up">
						<section className="section section-top">
							<div className="row d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<div className="">
										<h2 className="pe-sm-5">Who We Are?</h2>
									</div>
								</div>
								<div className="">
									<div className="">
										<p className="text-left">Adciasis Solutions is a leading software development and digital marketing company in India, providing a wide range of cutting-edge technology solutions to global businesses of all sizes across various industries. With our team of highly skilled professionals and state-of-the-art infrastructure, we have been delivering innovative and scalable solutions to our clients globally.</p>
										<p className="text-left">Our team has extensive expertise across a wide range of industries including healthcare, education, finance, GIS, law enforcement, sports, and transportation, allowing us to develop integrated solutions that automate business processes utilizing cutting-edge technologies such as Blockchain, Chatbots, AI, machine learning, data analytics, and Digital Marketing tools.</p>
									</div>
								</div>
							</div>
						</section>
						<section className="section">
							<div className="px-sm-3">
								<div className="row d-flex align-items-center bg-color-gray">
									<div className="col-md-6 col-sm-6 px-0 d-flex align-items-stretch" data-aos="fade-right" data-aos-delay="0">
										<div className="">
											<img alt="Our Vision" src="assets/img/our-vision.png" className="img-fluid" />
										</div>
									</div>
									<div className="col-md-6 col-sm-6 px-0 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay="0">
										<div className="text-center px-sm-5 my-5">
											<h2 className="">Our Vision</h2>
											<p className="text-center mx-5">We envision to become the preferred technology partner for businesses worldwide, by providing them with exceptional software development and digital marketing services that transform their operations and drive their growth.</p>
										</div>
									</div>
								</div>
								<div className="row d-flex align-items-center bg-color-light-blue">
									<div className="col-md-6 col-sm-6 px-0 d-flex align-items-stretch order-sm-last" data-aos="fade-left" data-aos-delay="0">
										<div className="">
											<img alt="Our Mission" src="assets/img/our-mision.png" className="img-fluid" />
										</div>
									</div>
									<div className="col-md-6 col-sm-6 d-flex align-items-stretch px-0 order-sm-first" data-aos="fade-right" data-aos-delay="0">
										<div className="text-center px-sm-5 my-5">
											<h2 className="">Our Mission</h2>
											<p className="text-center mx-5">Our mission is to empower businesses with the latest technology solutions that enhance their operations, optimize efficiency and drive growth.</p>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
				<section className="section" data-aos="fade-right" data-aos-delay="0">
					<div className="container">
						<div className="mx-sm-5">
							<div>
								<div className="text-center mb-sm-5">
									<h2>Our Core Values</h2>
									<p>We follow a comprehensive development process to ensure that your app meets your requirements and exceeds your expectations. Our process includes the following steps:</p>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
									<button className={`nav-link ${selectedTab === 1 ? " active" : ""}`} id="pills-one-tab" data-toggle="pill" data-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected={selectedTab === 1 ? true : false} onClick={() => setSelectedTab(1)}>
										Innovation
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className={`nav-link ${selectedTab === 2 ? " active" : ""}`} id="pills-two-tab" data-toggle="pill" data-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected={selectedTab === 2 ? true : false} onClick={() => setSelectedTab(2)}>
										Integrity
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className={`nav-link ${selectedTab === 3 ? " active" : ""}`} id="pills-three-tab" data-toggle="pill" data-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected={selectedTab === 3 ? true : false} onClick={() => setSelectedTab(3)}>
										Customer-centricity
									</button>
								</li>
								<li className="nav-item" role="presentation">
									<button className={`nav-link ${selectedTab === 4 ? " active" : ""}`} id="pills-four-tab" data-toggle="pill" data-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected={selectedTab === 4 ? true : false} onClick={() => setSelectedTab(4)}>
										Collaboration
									</button>
								</li>
							</ul>
							<div className="tab-content mt-5 pt-5 mx-sm-5" id="pills-tabContent">
								<div className={`tab-pane fade ${selectedTab === 1 ? " show active" : ""}`} id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
									<div className="text-center core-sec">
										<img alt="" className="icon-img" src="assets/icons/about/Innovation.svg" />
										<div className="mt-1 mx-sm-5">
											<h3>Innovation</h3>
											<p className="mt-4 mb-0">We strive to stay ahead of the curve by continually innovating and delivering solutions that exceed our clients' expectations.</p>
										</div>
									</div>
								</div>
								<div className={`tab-pane fade ${selectedTab === 2 ? " show active" : ""}`} id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
									<div className="text-center core-sec">
										<img alt="" className="icon-img" src="assets/icons/about/Integrity.svg" />
										<div className="mt-1 mx-sm-5">
											<h3>Integrity</h3>
											<p className="mt-4 mb-0">We conduct business with the utmost integrity, honesty, and transparency, ensuring that our clients can trust us completely.</p>
										</div>
									</div>
								</div>
								<div className={`tab-pane fade ${selectedTab === 3 ? " show active" : ""}`} id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">
									<div className="text-center core-sec">
										<img alt="" className="icon-img" src="assets/icons/about/Customer-centricity.svg" />
										<div className="mt-1 mx-sm-5">
											<h3>Customer-centricity</h3>
											<p className="mt-4 mb-0">We are dedicated to providing our clients with the highest level of customer service, focusing on their needs and ensuring their satisfaction.</p>
										</div>
									</div>
								</div>
								<div className={`tab-pane fade ${selectedTab === 4 ? " show active" : ""}`} id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab">
									<div className="text-center core-sec">
										<img alt="" className="icon-img" src="assets/icons/about/Collaboration.svg" />
										<div className="mt-1 mx-sm-5">
											<h3>Collaboration</h3>
											<p className="mt-4 mb-0">We believe in working collaboratively with our clients, our team members, and our partners to deliver the best possible solutions.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<section className="section-top light-bg">
						<div className="container mb-5" data-aos="fade-up">
							<div className="row">
								<div className="col-md-6 col-sm-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="">
										<img alt="Why Choose" className="img-fluid" src="assets/img/about-us_why_choose_us.png" />
									</div>
								</div>
								<div className="col-md-6 col-sm-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="ps-sm-3 ps-sm-5">
										<h2 className="pe-sm-5 mt-5 mt-sm-0">Why Adciasis</h2>
										<ul className="ic-check-list">
											<li>Experienced Team</li>
											<li>Customer-Focused Approach</li>
											<li>Innovative Solutions</li>
											<li>Competitive Pricing</li>
											<li>Timely Delivery</li>
											<li>Agile Methodology</li>
											<li>Quality Assurance</li>
											<li>Data-Driven Approach</li>
											<li>Strong Industry Expertise</li>
											<li>Continuous Support</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
				</section>
				<section className="section">
					<div className="container" data-aos="fade-up">
						<div className="row d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
							<div className="col-12">
								<div className="">
									<h2 className="pe-sm-5">Our Working Mechanism</h2>
								</div>
							</div>
							<div className="col-12">
								<div className="">
									<p className="text-left">At Adciasis Solutions, we follow a well-defined process for software development and digital marketing that ensures the timely delivery of high-quality solutions. We believe in working closely with our clients and keeping them involved throughout the entire process. Here's a brief overview of how we work:</p>
								</div>
							</div>
						</div>
						<div className="row working-mech-section">
							<div className="col-sm-6 pe-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">01</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Discovery</h4>
										<p>We begin by understanding your business needs and requirements, which involves gathering information about your goals, target audience, budget, and timelines.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 ps-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">02</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Planning</h4>
										<p>Based on the information gathered during the discovery phase, we create a detailed project plan that outlines the scope of work, milestones, timelines, and deliverables.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 pe-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">03</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Design</h4>
										<p>For software development projects, we create wireframes and mockups that illustrate the application’s user interface and user experience. We develop a comprehensive strategy for digital marketing projects that includes audience targeting, messaging, and campaign execution.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 ps-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">04</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Development</h4>
										<p>Our team of developers works on developing the software solution or implementing the digital marketing strategy. We follow an agile software development methodology, allowing us to be flexible and responsive to your changing business needs.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 pe-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">05</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Quality Assurance</h4>
										<p>Once the solution is developed, we perform rigorous testing to ensure that it meets the highest quality standards and is free from defects.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 ps-sm-5">
								<div className="row d-flex mt-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">06</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Deployment</h4>
										<p>We deploy the solution to your production environment and provide ongoing support to ensure that it performs optimally.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 pe-sm-5">
								<div className="row d-flex mt-5 mt-sm-5 align-items-stretch" data-aos="fade-up" data-aos-delay="0">
									<div className="col-sm-2 col-3">
										<div className="sname">07</div>
									</div>
									<div className="col-sm-10 col-9">
										<h4>Optimization</h4>
										<p>For digital marketing projects, we analyze campaign data and optimize the campaigns for maximum performance. For software development projects, we provide ongoing maintenance and support to ensure that the solution continues to meet your evolving business needs.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				<section className="section pb-0">
					<div className="tech-logo-section">
						<div className="container py-5" data-aos="fade-up" data-aos-delay="0">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<h2 className="pe-sm-5 text-center pt-5">Technologies We Use</h2>
								</div>
								<div className="col-md-12 col-sm-12 align-items-stretch">
									<div className="">
										<ul className="ic-tech-list mt-0">
											<li className="">
												<img alt="iOS" src="assets/img/tech/ios.png" />
											</li>
											<li className="">
												<img alt="Android" src="assets/img/tech/android.png" />
											</li>
											<li className="">
												<img alt="Python" src="assets/img/tech/python.png" />
											</li>
											<li className="">
												<img alt="MongoDB" src="assets/img/tech/mongoDB.png" />
											</li>
											<li className="">
												<img alt="Java Script" src="assets/img/tech/java-script.png" />
											</li>
											<li className="">
												<img alt="Jquery" src="assets/img/tech/jquery.png" />
											</li>
											<li className="">
												<img alt="Node JS" src="assets/img/tech/node.png" />
											</li>
											<li className="">
												<img alt="Angular JS" src="assets/img/tech/angular.png" />
											</li>
											<li className="">
												<img alt="React" src="assets/img/tech/react.png" />
											</li>
											<li className="">
												<img alt="PHP" src="assets/img/tech/PHP.png" />
											</li>
											<li className="">
												<img alt="Java" src="assets/img/tech/java.png" />
											</li>
											<li className="">
												<img alt="C-Hash" src="assets/img/tech/C-hash.png" />
											</li>
											<li className="">
												<img alt=".Net" src="assets/img/tech/dotnet.png" />
											</li>
											<li className="">
												<img alt="CSS HTML" src="assets/img/tech/html-css.png" />
											</li>
											<li className="">
												<img alt="Swift" src="assets/img/tech/swift.png" />
											</li>
											<li className="">
												<img alt="Object-C" src="assets/img/tech/Object-C.png" />
											</li>
											<li className="">
												<img alt="Kotlin" src="assets/img/tech/kotlin.png" />
											</li>
											<li className="">
												<img alt="Flutter" src="assets/img/tech/flutter.png" />
											</li>
											<li className="">
												<img alt="React Native" src="assets/img/tech/react-native.png" />
											</li>
											<li className="">
												<img alt="Magento" src="assets/img/tech/magento.png" />
											</li>
											<li className="">
												<img alt="MySQL" src="assets/img/tech/mysql.png" />
											</li>
											<li className="">
												<img alt="PostgreSQL" src="assets/img/tech/PostgreSQL.png" />
											</li>
											<li className="">
												<img alt="Wordpress" src="assets/img/tech/wordpress.png" />
											</li>
											<li className="">
												<img alt="Laravel" src="assets/img/tech/laravel.png" />
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <div className="one-stop_section my-0">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-lg-8 col-md-8 " data-aos="fade-up" data-aos-delay="100">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div> */}
			</main>
		</>
	);
};
export default About;
