import React from "react";

const Header = (props) => {
	const [hclass, setHclass] = React.useState("");
	React.useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 100) {
				setHclass("header-scrolled");
			} else {
				setHclass("");
			}
		};
		// clean up code
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<>
			<header id="header" className={`fixed-top ${hclass}`}>
				<nav className="navbar navbar-expand-lg">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img src="assets/img/adciasis-logo.svg" alt="Adciasis Logo" />
						</a>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
							<i className="bi bi-list"></i>
						</button>
						<div className="collapse navbar-collapse" id="navbarScroll">
							<ul className="navbar-nav ms-auto my-2 my-lg-0">
								<li className="nav-item">
									<a className="nav-link active" aria-current="page" href="/">
										Home
									</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" href="/web-design" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Services
									</a>
									<ul className="dropdown-menu">
										<div className="mmdd">
											<div className="container">
												<div className="row">
													<div className="col-sm-8">
														<div className="row">
															<li className="col-sm-7">
																<a className="dropdown-item" href="/mobile-app-developemnt">
																	Mobile App Development
																</a>
															</li>
															<li className="col-sm-5">
																<a className="dropdown-item" href="/media-services">
																	Media Services
																</a>
															</li>
															<li className="col-sm-7">
																<a className="dropdown-item" href="/web-design">
																	Web Design & Development
																</a>
															</li>
															<li className="col-sm-5">
																<a className="dropdown-item" href="/enterprise-applications">
																	Enterprise Applications
																</a>
															</li>
															<li className="col-sm-7">
																<a className="dropdown-item" href="/digital-marketing-services">
																	Digital Marketing Services
																</a>
															</li>
															<li className="col-sm-5">
																<a className="dropdown-item" href="/strategy">
																	Strategy
																</a>
															</li>
														</div>
													</div>
													<div className="col-sm-4 justify-content-center d-none d-sm-block justify-content-center text-center">
														<img alt="" className="responsive-fuild mmdd-img text-center" src="assets/img/open-service-panel-icon.svg" />
														<a className="menu-img-link justify-content-center" href="/contact-us">
															Innovate Your Way to Success <i className="bi bi-chevron-double-right"></i>
														</a>
													</div>
												</div>
											</div>
										</div>
									</ul>
								</li>
								{/* <li className="nav-item">
									<a className="nav-link" href="/work">
										Work
									</a>
								</li> */}
								<li className="nav-item">
									<a className="nav-link" href="/blogs">
										Blogs
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/about-us">
										About Us
									</a>
								</li>
								<li className="nav-item pe-sm-0">
									<a className="nav-link" href="/contact-us">
										Contact Us
									</a>
								</li>
							</ul>
							{/* <div className="d-flex">
              <a className="getstarted scrollto" href="/contact-us">Start a Project</a>
            </div> */}
						</div>
					</div>
				</nav>
			</header>
		</>
	);
};
export default Header;
