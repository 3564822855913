import React from "react";

const WebDesign = (props) => {
	return (
		<>
			<section id="hero" className="digital-market_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>
									Digital Marketing <br />
									Solutions
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section section-top">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Maximize Your Online Reach</h2>
									<p className="text-left mb-5">With the world moving towards digitalization, it is essential for businesses to have a strong online presence to succeed. We offer a comprehensive suite of digital marketing solutions that help your business grow and thrive in the online space.</p>
									<p className="mb-0">Our team of expert marketers has a wealth of experience in creating customized digital marketing campaigns that deliver results. We use the latest technologies and tools to create data-driven marketing strategies that are tailored to your business needs and goals.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-bg py-5 mt-5">
					<div className="container">
						<h2><strong>Digital Marketing Solutions that increase brand recognition, draw in new customers, and increase sales.</strong></h2>
					</div>
				</section>
				<section className="web-development-section bg-white">
					<div className="container" data-aos="fade-up">
						<div className="mt-5">
							<div className="row">
								<div className="col-sm-6 d-flex align-items-center text-center" data-aos="fade-left" data-aos-delay="0">
									<img alt="" className="img-fluid" src="assets/img/dm-services.png" />
								</div>
								<div className="col-sm-6">
									<div className="mx-sm-5" data-aos="fade-right" data-aos-delay="0">
										<h2 className="">Digital Marketing Services</h2>
										<div>
											<div className="accordion" id="developmentservices">
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingOne">
														<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
															Search Engine Optimisation (SEO)
														</button>
													</h3>
													<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our SEO experts use proven techniques to improve your website's visibility on search engine result pages (SERPs). By optimizing your website's content, keywords, and meta tags, we can help your website rank higher on search engines, resulting in more traffic and leads.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingTwo">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
															Search Engine Marketing (SEM)
														</button>
													</h3>
													<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our SEM services focus on promoting your website through paid advertising campaigns on search engines like Google and Bing. We create targeted ads that reach potential customers and help drive traffic to your website.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingThree">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
															Social Media Management
														</button>
													</h3>
													<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our social media management team helps you manage and grow your online presence on popular social media platforms like Facebook, Twitter, and Instagram. We create engaging content, respond to customer inquiries, and build relationships with your audience to help increase brand awareness and loyalty.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingFour">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
															Pay Per Click (PPC)
														</button>
													</h3>
													<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our PPC services include creating and managing targeted ads that drive traffic to your website. By carefully selecting keywords and demographics, we ensure that your ads are seen by the right people at the right time.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingFive">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
															Online Reputation Management
														</button>
													</h3>
													<div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our online reputation management services help you monitor and manage your brand's online reputation. Being a reliable provider of all Branding solutions, we track mentions of your brand online and respond to negative reviews or comments to ensure that your online presence is positive and reputable.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingSix">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
															Email Marketing
														</button>
													</h3>
													<div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our email marketing services help you create targeted email campaigns that drive conversions and customer engagement. We use data analytics to track email opens, click-through rates, and other key metrics to improve campaign performance.</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="one-stop_section">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-md-11" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
				<section className="section approch_section">
					<div className="container  mb-5" data-aos="fade-up">
						<div className="row d-flex align-items-stretch justify-content-center">
							<div className="col-md-9 col-sm-9 align-items-stretch justify-content-center" data-aos="fade-up" data-aos-delay="0">
								<h2 className="pe-sm-5 text-center mb-5">Our Digital Marketing Approach</h2>
								<div>
									<p className="text-center mb-5">Our data-driven approach ensures that we create customized digital marketing plans tailored to your business needs. Trust us to help you achieve your digital marketing goals with our proven strategies, techniques & approaches.</p>
								</div>
							</div>
						</div>
						<div className="row d-flex">
							<div className="col-md-3 col-sm-3 mt-5 align-items-stretch justify-content-center text-center sidemark-arrow">
								<div className="mb-4 img">
									<img alt="" src="assets/icons/dm/dm-audit.svg" />
								</div>
								<h3 className="px-sm-4">Digital Marketing Audit</h3>
							</div>
							<div className="col-md-3 col-sm-3 mt-5 align-items-stretch justify-content-center text-center sidemark-arrow">
								<div className="mb-4 img">
									<img alt="" src="assets/icons/dm/dm-strategy.svg" />
								</div>
								<h3 className="px-sm-4">Create Digital Marketing Strategy</h3>
							</div>
							<div className="col-md-3 col-sm-3 mt-5 align-items-stretch justify-content-center text-center sidemark-arrow">
								<div className="mb-4 img">
									<img alt="" src="assets/icons/dm/mc-marketing.svg" />
								</div>
								<h3 className="px-sm-4">Selection of Multi-Channel Marketing</h3>
							</div>
							<div className="col-md-3 col-sm-3 mt-5 align-items-stretch justify-content-center text-center">
								<div className="mb-4 img">
									<img alt="" src="assets/icons/dm/dm-optimization.svg" />
								</div>
								<h3 className="px-sm-4">Conversion Optimization</h3>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default WebDesign;
