import { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import ContactForm from '../../components/Common/ContactForm'

const Contact = () => {
  const [loader, setLoader] = useState(false)
  const handleLoading = (isLoading) => {
    setLoader(isLoading)
  }
  return (
    <div>
      <section id='hero' className='contact_banner otherpage-hero'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-sm-7 align-self-center'>
              <div className='hero-container' data-aos='fade-left' data-aos-delay='0'>
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loader && (
        <div
          className='loader'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5000,
            height: '100%',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(0,0,0,0.8',
          }}>
          <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='96' visible={true} />
        </div>
      )}
      <main id='main'>
        <section className='section-top contact'>
          <div className='container' data-aos='fade-up' data-aos-delay='0'>
            <div className='row'>
              <div className='col-sm-6'>
                <h1>Contact Info</h1>
                <div className='row'>
                  <div className='col-12'>
                    <div className='info-box'>
                      <div className='info-icon'>
                        <i className='bi bi-house-door-fill'></i>
                      </div>
                      <div className='info-content'>
                        <h3>Address</h3>
                        <p>
                          F-226A, Upper Ground Floor, <br />
                          Behind Kali Mandir, Lado Sarai,
                          <br />
                          New Delhi - 110030, India
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='info-box mt-4'>
                      <div className='info-icon'>
                        <i className='bi bi-telephone-outbound-fill'></i>
                      </div>
                      <div className='info-content'>
                        <h3>Call Us On</h3>
                        <p>+91-8340577517</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='info-box mt-4'>
                      <div className='info-icon'>
                        <i className='bi bi-envelope-fill'></i>
                      </div>
                      <div className='info-content'>
                        <h3>Mail Us On</h3>
                        <p>
                          <a href='mailto:contact@adciasis.com'>contact@adciasis.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 mt-5 mt-sm-0 mt-lg-0'>
                <h1>GET IN TOUCH WITH US</h1>
                <ContactForm setLoader={handleLoading} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
export default Contact
