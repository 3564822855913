import ContactForm from '../../components/Common/ContactForm'
import { useState } from 'react'
import { RotatingLines } from 'react-loader-spinner'

const Home = () => {
  const [loader, setLoader] = useState(false)
  const handleLoading = (isLoading) => {
    setLoader(isLoading)
  }
  return (
    <>
      <section id='hero' className='homepage_banner homepage-hero'>
        {loader && (
          <div
            className='loader'
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 5000,
              height: '100%',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgba(0,0,0,0.8',
            }}>
            <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='96' visible={true} />
          </div>
        )}
        <div className='container'>
          <div className='row'>
            <div className='hero-container' data-aos='fade-left' data-aos-delay='0'>
              <div className='col-md-8 col-sm-9'>
                <h1>Elevate your Business with Smarter Digital Solutions</h1>
                <h4>Discover how our cutting-edge solutions can revolutionize the way you do business</h4>
                <div>
                  <button type='button' class='btn btn-primary getstarted scrollto' data-bs-toggle='modal' data-bs-target='#staticBackdrop'>
                    Start a Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id='main'>
        <section className='section section-top'>
          <div className='container'>
            <div className='row' data-aos='fade-up' data-aos-delay='0'>
              <div className='col-md-4 col-sm-4 d-flex align-items-stretch'>
                <div className=''>
                  <h2 className='pe-sm-5'>Full-Service Digital Agency</h2>
                </div>
              </div>
              <div className='col-md-8 col-sm-8 d-flex align-items-stretch'>
                <div className=''>
                  <p className='text-left mb-3'>Adciasis is a prominent digital business solution provider with an energetic team of creative professionals dedicated to delivering innovative and effective solutions for all your digital needs. From app design and development to digital marketing and strategy building, we offer a comprehensive range of services that can help take your business to the next level.</p>
                  <a className='text-link mt-5' href='/about-us'>
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className='row services-head' data-aos='fade-up' data-aos-delay='0'>
              <div className='col-md-4 col-sm-4 d-flex align-items-stretch'>
                <div className='pe-sm-5 me-sm-3'>
                  <h2 className='pe-sm-5'>Our Services</h2>
                  <p className='mb-sm-0'>
                    Adciasis offers a comprehensive range of innovative solutions that can help your business stay competitive in today's digital age.
                    <br />
                    <br />
                    Have a look at the services we offer:
                  </p>
                </div>
              </div>
              <div className='col-md-8 col-sm-8 px-sm-4 d-flex align-items-stretch'>
                <div className='row'>
                  <div className='col-sm-4 col-6'>
                    <a href='/mobile-app-developemnt'>
                      <img alt='' src='/assets/icons/homepage/01-mobile-app-development.svg' />
                      <h3>Mobile App Development</h3>
                    </a>
                  </div>
                  <div className='col-sm-4 col-6'>
                    <a href='/web-design'>
                      <img alt='' src='/assets/icons/homepage/02-web-design-and-development.svg' />
                      <h3>Web Design & Development</h3>
                    </a>
                  </div>
                  <div className='col-sm-4 col-6'>
                    <a href='/digital-marketing-services'>
                      <img alt='' src='/assets/icons/homepage/03-digital-marketing-services.svg' />
                      <h3>Digital Marketing Services</h3>
                    </a>
                  </div>
                  <div className='col-sm-4 col-6'>
                    <a href='/enterprise-applications'>
                      <img alt='' src='/assets/icons/homepage/04-enterprise-development.svg' />
                      <h3>Enterprise Applications</h3>
                    </a>
                  </div>
                  <div className='col-sm-4 col-6'>
                    <a href='/media-services'>
                      <img alt='' src='/assets/icons/homepage/06-media-services.svg' />
                      <h3>Media Services</h3>
                    </a>
                  </div>
                  <div className='col-sm-4 col-6'>
                    <a href='/strategy'>
                      <img alt='' src='/assets/icons/homepage/07-strategy.svg' />
                      <h3>Strategy</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='one-stop_section'>
          <div className='container'>
            <div className='row justify-content-center' data-aos='fade-up' data-aos-delay='0'>
              <div className='col-sm-11'>
                <h2 className='text-center'>Discover Efficiency & Profitability with our Smart Solutions</h2>
                <h5 className='text-center'>Let our incredible team assist you in unlocking your digital needs.</h5>
                <a className='getstarted' href='/contact-us'>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className='section blog_section'>
          <div className='container'>
            <div className='row d-flex align-items-stretch' data-aos='fade-up' data-aos-delay='0'>
              <div className='col-6'>
                <h2>Blogs</h2>
              </div>
              <div className='col-6 text-end'>
                <a className='blog-viewmore-link' href='/blogs'>
                  View All
                </a>
              </div>
            </div>
            <div className='row d-flex align-items-stretch' data-aos='fade-up' data-aos-delay='0'>
              <div className='col-lg-6 col-md-6'>
                <div className='card me-sm-5'>
                  <img src='/assets/img/blog.jpg' className='img-top' alt='...' />
                  <div className='card-body'>
                    <div className='blog-tag'>
                      <a href='/strategy'>Market Strategy</a>
                    </div>
                    <h5 className='title'>
                      <a href='/blog-detail'>5 Steps to Create an Outstanding Marketing Plan</a>
                    </h5>
                    <p className='date'>Dec 10, 2022</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
                <div className='card  ms-sm-5'>
                  <img src='/assets/img/blog.jpg' className='img-top' alt='...' />
                  <div className='card-body'>
                    <div className='blog-tag'>
                      <a href='/strategy'>Market Strategy</a>
                    </div>
                    <h5 className='title'>
                      <a href='/blog-detail'>5 Steps to Create an Outstanding Marketing Plan</a>
                    </h5>
                    <p className='date'>Dec 10, 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div class='modal fade' id='staticBackdrop' tabindex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
        <div class='modal-dialog modal-xl modal-project modal-dialog-centered'>
          <div class='modal-content'>
            {/* <div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">New message</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div> */}
            <div class='modal-body'>
              <div className='row'>
                <div className='col-sm-6 pt-5'>
                  <div className='px-3 px-sm-5 pt-sm-3'>
                    <h1 className='white'>
                      Take the First Step Towards <span className='gr'>Innovation.</span>
                    </h1>
                    <h3 className='white'>Contact us to get your project started TODAY. We will be sure to get back to you as soon as possible.</h3>
                  </div>
                </div>
                <div className='col-sm-6 pt-5 white-bg'>
                  <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                  <div className='contact px-3 px-sm-5 pb-3 mb-2'>
                    <h2>Please fill the form below:</h2>
                    <ContactForm setLoader={handleLoading} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="button" class="btn btn-primary">Send message</button>
					</div> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default Home
