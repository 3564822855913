import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react'
import startsWith from 'lodash.startswith'

const ContactForm = ({ setLoader }) => {
  const [phoneErr, setPhoneErr] = useState(false)
  const initialValues = {
    name: '',
    email: '',
    mobileNo: '91',
    queryType: '',
    description: '',
  }

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Too short! The name should be more than 3 characters.')
      .matches(/^[a-zA-Z ]+$/, 'The name field should not contain special characters or numbers. Validation on empty or space chars without text.')
      .required('Please enter your name'),
    email: Yup.string().email('Please enter a valid email id').required('Please enter your email'),
    mobileNo: Yup.string().matches(phoneRegExp, 'Please enter a valid mobile number').required('Please enter your mobile number'),
    queryType: Yup.string().required('Please select a service'),
    description: Yup.string().min(50, 'Too short! The message should be more than 50 chars').required('Please write us your query'),
  })

  const handleSubmit = async (values, actions) => {
    try {
      const { name, email, mobileNo, description, queryType } = values
      setLoader(true)
      const { data } = await axios.post('https://newitzone.com/api/query', {
        name,
        email,
        mobileNo: mobileNo,
        description,
        queryType: queryType,
        isSiteChange: 1,
      })
      data.error ? toast.error(data.error) : toast.success(data.message)
      actions.resetForm()
      setLoader(false)
    } catch (error) {
      setLoader(false)
      toast.error(error.message)
    }
  }
  const handleChange = (e, setValues) => {
    setValues((prev) => ({ ...prev, mobileNo: e }))
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(props) => (
          <Form className='page-form'>
            <div className='row'>
              <div className='col-12 form-group'>
                <input type='text' name='name' className={`form-control ${props.errors.name && props.touched.name ? 'is-invalid' : ''}`} id='name' placeholder='Your Name*' onChange={props.handleChange} value={props.values.name} onBlur={props.handleBlur} />
                {props.errors.name && props.touched.name ? (
                  <div id='feedback' className='text-danger'>
                    {props.errors.name}
                  </div>
                ) : null}
              </div>
              <div className='col-12 form-group mt-4'>
                <input type='email' className={`form-control ${props.errors.email && props.touched.email ? 'is-invalid' : ''}`} name='email' id='email' placeholder='Email Address*' onChange={props.handleChange} value={props.values.email} onBlur={props.handleBlur} />
              </div>
              {props.errors.email && props.touched.email ? (
                <div id='feedback' className='text-danger'>
                  {props.errors.email}
                </div>
              ) : null}
              <div className='col-12 form-group mt-4'>
                <PhoneInput
                  country={'in'}
                  className={`form-control ${props.errors.mobileNo && props.touched.mobileNo ? 'is-invalid' : ''}`}
                  enableAreaCodes={true}
                  countryCodeEditable={false}
                  enableSearch={true}
                  inputProps={{
                    name: 'mobileNo',
                    id: 'mobileNo',
                    placeholder: 'Phone Number*',
                    required: true,
                  }}
                  inputStyle={{
                    border: 'none',
                  }}
                  buttonStyle={{
                    border: 'none',
                    background: 'transparent',
                  }}
                  value={props.values.mobileNo}
                  onChange={(e) => handleChange(e, props.setValues)}
                  onBlur={() =>
                    props.handleBlur({
                      target: {
                        name: 'mobileNo',
                      },
                    })
                  }
                />
              </div>
              {props.errors.mobileNo && props.touched.mobileNo ? (
                <div id='feedback' className='text-danger'>
                  {props.errors.mobileNo}
                </div>
              ) : null}
              {/* <div className="col-12 form-group mt-3">
											<input type="text" name="name" className="form-control" id="name" placeholder="Your Name*"  />
										</div> */}
              <div className='col-12 form-group mt-4'>
                <select name='queryType' className={`form-select form-control ${props.errors.queryType && props.touched.queryType ? 'is-invalid' : ''}`} value={props.values.queryType} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <option value=''>Select a service</option>
                  <option value='mobileapp'>Mobile App Development</option>
                  <option value='webdesign'>Web Design & Development</option>
                  <option value='media'>Media Services</option>
                  <option value='digital'>Digital Marketing Services</option>
                  <option value='enterprise'>Enterprise Applications</option>
                  <option value='strategy'>Strategy</option>
                  <option value='other'>Other</option>
                </select>
              </div>
              {props.errors.queryType && props.touched.queryType ? (
                <div id='feedback' className='text-danger'>
                  {props.errors.queryType}
                </div>
              ) : null}
              <div className='col-12 form-group mt-4'>
                <textarea className={`form-control ${props.errors.description && props.touched.description ? 'is-invalid' : ''}`} name='description' rows='4' placeholder='Message*' onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.description}></textarea>
              </div>
              {props.errors.description && props.touched.description ? (
                <div id='feedback' className='text-danger'>
                  {props.errors.description}
                </div>
              ) : null}
            </div>
            <div className=''>
              <button type='submit'>Submit</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ContactForm
