import React from "react";

const Footer = (props) => {
	return (
		<footer id="footer">
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-md-11 col-sm-10 col-10">
							<div className="footer-info footer-links">
								<div className="row">
									<div className="col-sm-2 col-3">
										<img src="/assets/img/footer-adciasis-logo.svg" alt="Adciasis icon Logo" />
									</div>
									<div className="col-sm-10 col-9">
										<div className="row">
											<div className="col-md-2 col-sm-2">
												<div>
													<a href="/about-us">About Us</a>
												</div>
												<div>
													<a href="/contact-us">Contact Us</a>
												</div>
												<div>
													<a href="/blogs">Blogs</a>
												</div>
											</div>
											<div className="col-md-4 col-sm-4">
												<div>
													<a href="/mobile-app-developemnt">Mobile App Development</a>
												</div>
												<div>
													<a href="/web-design">Web Design & Development</a>
												</div>
												<div>
													<a href="/media-services">Media Services</a>
												</div>
											</div>
											<div className="col-md-4 col-sm-4">
												<div>
													<a href="/digital-marketing-services">Digital Marketing Services</a>
												</div>
												<div>
													<a href="/enterprise-applications">Enterprise Applications</a>
												</div>
												<div>
													<a href="/strategy">Strategy</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-1 col-sm-2 col-2">
							<div className="social-links mt-3 float-sm-end">
								<a href="https://twitter.com/adciasis" target="new" className="twitter">
									<i className="bi bi-twitter"></i>
								</a>
								<a href="https://www.linkedin.com/company/adciasis-solutions-pvt-ltd/" target="new" className="facebook">
									<i className="bi bi-linkedin"></i>
								</a>
								<a href="https://www.facebook.com/Adciasis?mibextid=LQQJ4d" target="new" className="instagram">
									<i className="bi bi-facebook"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-6 offset-md-6">
						<div className="copyright">
							© Adciasis 2023 – All rights reserved.
							<div className="footer-bot d-sm-inline-block ms-sm-4">
								<a href="/terms-of-use">Terms of Use</a><span className="px-3">|</span><a href="/privacy-policy">Privacy Policy</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
