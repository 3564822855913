import React from "react";

const WebDesign = (props) => {
	return (
		<>
			<section id="hero" className="web-design_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>
									Web Design
									<br />& Development
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<main id="main">
				<section className="section section-top">
					<div className="container" data-aos="fade-up">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Unleash Your Digital Presence</h2>
									<p className="text-left mb-5">In today's digital world, having a website that stands out from the crowd is essential for businesses of all sizes. As a professional Web Application Development Company, we understand the importance of a well-designed and functional website, and we're here to help you achieve your goals.</p>
									<p className="text-left mb-5">Our team of expert designers and developers has a wealth of experience in creating visually stunning and highly functional websites for a wide range of industries. Our specialization lies in creating responsive websites that are optimized for performance, speed, and user engagement.</p>
									<p className="mb-0">We believe that a great website should not only look good but also perform well. That's why we use the latest web design and development technologies and techniques to create websites that are user-friendly, fast-loading, and mobile-responsive. Whether you need a simple website or a complex e-commerce platform, our experienced team can deliver a website that is visually appealing, easy to use, and optimized for search engines. </p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="web-development-section mt-5">
					<div className="container" data-aos="fade-up">
						<div className="pt-5 pb-4">
							<div className="row">
								<div className="col-sm-6 d-flex align-items-center" data-aos="fade-left" data-aos-delay="0">
									<img alt="Web Development Services" className="img-fluid mb-5 mb-sm-0" src="assets/img/web-development-services.png" />
								</div>
								<div className="col-sm-6">
									<div className="mx-sm-5" data-aos="fade-right" data-aos-delay="0">
										<h2 className="">Our Web Development Services</h2>
										<div>
											<div className="accordion" id="developmentservices">
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingOne">
														<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
															E-Commerce Development
														</button>
													</h3>
													<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#developmentservices">
														<div className="accordion-body">We offer end-to-end e-commerce and M-Commerce Application Development Services that enable businesses to sell their products and services online. Our team works closely with clients to create responsive, user-friendly e-commerce platforms that are optimized for maximum conversion rates. We use the latest e-commerce technologies and platforms such as Magento, Shopify, WooCommerce, and more to create online stores that are tailored to the specific needs of each business.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingTwo">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
															Portal Development
														</button>
													</h3>
													<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#developmentservices">
														<div className="accordion-body">Be it a private or web business portal, We provides you with a product loaded with functionality and having an efficient user interface.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingThree">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
															Web Application Development
														</button>
													</h3>
													<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#developmentservices">
														<div className="accordion-body">A responsive, interactive and flexible web application is key to the growth of several kinds of organizations, enhancing its reach online by cross-platform development.</div>
													</div>
												</div>
												<div className="accordion-item">
													<h3 className="accordion-header" id="headingFour">
														<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
															Custom Web Development
														</button>
													</h3>
													<div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#developmentservices">
														<div className="accordion-body">Our custom websites are excellent in functionality as well as appeal, and are designed keeping your business requirements in close view.</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <section className="digital-section" data-aos="fade-up" data-aos-delay="100">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-sm-7">
                        <h2 className="mb-4">Digital experience that connects the brand to the users</h2>
                    </div>
                    <div className="col-md-5 col-sm-5">
                        <div className="text-sm-end text-center pt-3">
                        <a className="getstarted" href="/contact-us">Start a Project</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
				<div className="one-stop_section my-0">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-md-11" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
				<section className="section py-0">
					<div className="tech-logo-section">
						<div className="container py-5"  data-aos="fade-up" data-aos-delay="0">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<h2 className="pe-sm-5 text-center pt-5">Technologies We Use</h2>
								</div>
								<div className="col-md-12 col-sm-12 align-items-stretch">
									<div className="">
										<ul className="ic-tech-list mt-0">
											<li className="">
												<img alt="iOS" src="assets/img/tech/ios.png" />
											</li>
											<li className="">
												<img alt="Android" src="assets/img/tech/android.png" />
											</li>
											<li className="">
												<img alt="Python" src="assets/img/tech/python.png" />
											</li>
											<li className="">
												<img alt="MongoDB" src="assets/img/tech/mongoDB.png" />
											</li>
											<li className="">
												<img alt="Java Script" src="assets/img/tech/java-script.png" />
											</li>
											<li className="">
												<img alt="Jquery" src="assets/img/tech/jquery.png" />
											</li>
											<li className="">
												<img alt="Node JS" src="assets/img/tech/node.png" />
											</li>
											<li className="">
												<img alt="Angular JS" src="assets/img/tech/angular.png" />
											</li>
											<li className="">
												<img alt="React" src="assets/img/tech/react.png" />
											</li>
											<li className="">
												<img alt="PHP" src="assets/img/tech/PHP.png" />
											</li>
											<li className="">
												<img alt="Java" src="assets/img/tech/java.png" />
											</li>
											<li className="">
												<img alt="C-Hash" src="assets/img/tech/C-hash.png" />
											</li>
											<li className="">
												<img alt=".Net" src="assets/img/tech/dotnet.png" />
											</li>
											<li className="">
												<img alt="CSS HTML" src="assets/img/tech/html-css.png" />
											</li>
											<li className="">
												<img alt="Swift" src="assets/img/tech/swift.png" />
											</li>
											<li className="">
												<img alt="Object-C" src="assets/img/tech/Object-C.png" />
											</li>
											<li className="">
												<img alt="Kotlin" src="assets/img/tech/kotlin.png" />
											</li>
											<li className="">
												<img alt="Flutter" src="assets/img/tech/flutter.png" />
											</li>
											<li className="">
												<img alt="React Native" src="assets/img/tech/react-native.png" />
											</li>
											<li className="">
												<img alt="Magento" src="assets/img/tech/magento.png" />
											</li>
											<li className="">
												<img alt="MySQL" src="assets/img/tech/mysql.png" />
											</li>
											<li className="">
												<img alt="PostgreSQL" src="assets/img/tech/PostgreSQL.png" />
											</li>
											<li className="">
												<img alt="Wordpress" src="assets/img/tech/wordpress.png" />
											</li>
											<li className="">
												<img alt="Laravel" src="assets/img/tech/laravel.png" />
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default WebDesign;
