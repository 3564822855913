import React from "react";

const Blogs = (props) => {
	return (
		<>
			<section id="hero" className="blog_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>Blog</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section blog_section section-top">
					<div className="container">
						<div className="row d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
							<div className="col-lg-3 col-md-3 mb-4">
								<div className="card">
									<img src="/assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 mb-4">
								<div className="card">
									<img src="/assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 mb-4">
								<div className="card">
									<img src="/assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-3 mb-4">
								<div className="card">
									<img src="/assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="row">
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/web-design">Web Development</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">What is the fundamental of web development?</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/">Design Fundamentals</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">What are the Top Digital Marketing Trends for 2023?</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>

							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/digital-marketing-services">Design Fundamentals</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">What are the Top Digital Marketing Trends for 2023?</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/web-design">Web Development</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">What is the fundamental of web development?</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="100">
								<div className="card">
									<img src="assets/img/blog.jpg" className="img-top" alt="..." />
									<div className="card-body">
										<div className="blog-tag">
											<a href="/strategy">Market Strategy</a>
										</div>
										<h5 className="title">
											<a href="/blog-detail">5 Steps to Create an Outstanding Marketing Plan</a>
										</h5>
										<p className="date">Dec 10, 2022</p>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</section>
			</main>
		</>
	);
};
export default Blogs;
