import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import Layout from "./components/Layout/";

//toaster
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// Import scss
import "./assets/css/theme.css";

const App =()=>{
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
        <ToastContainer />
      </React.Fragment>
    );
}

export default App;
