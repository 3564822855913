import React from "react";

const PrivacyPolicy=(props)=>{
    return (<>
        <section id="hero" className="about_banner otherpage-hero">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-7 align-self-center">
                        <div className="hero-container" data-aos="fade-left" data-aos-delay="0">
                            <h1>Privacy Policy</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <main id="main">
            <div className="otherpage">
                <div className="container" data-aos="fade-up">
                    <section className="section section-top">
                        <div className="row d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
                            {/* <div className="">
                                <div className="">
                                    <h2 className="pe-sm-5">Privacy Policy</h2>
                                </div>
                            </div> */}
                            <div className="">
                                <div className="">
                                    <p className="text-left mb-5">This Privacy Policy describes how Adciasis ("we," "our," or "us") collects, uses, and protects the personal information of visitors ("users," "you," or "your") to our website. We are committed to ensuring the privacy and security of your personal information.</p>
                                    
                                    <h2>Information We Collect:</h2>
                                    <ol className="mb-5">
                                        <li>Personal Information: We may collect personal information such as your name, email address, phone number, and job title when you voluntarily provide it to us through contact forms or when subscribing to our newsletters.</li>
                                        <li>Log Data: Our website automatically collects certain information when you visit, including your IP address, browser type, operating system, referring URLs, and the date and time of your visit. This information is used for analytical purposes and to improve our website's performance and user experience.</li>
                                        <li>Cookies: We may use cookies and similar technologies to enhance your browsing experience and personalize content. Cookies are small files stored on your device that allow us to recognize your preferences and tailor our website to better suit your needs.</li>
                                    </ol>
                                    
                                    <h2>Use of Information:</h2>
                                    <ol className="mb-5">
                                        <li>Personal Information: We may use the personal information you provide to respond to your inquiries, send you requested information, and keep you updated about our products, services, and promotions, if you have opted to receive such communications.</li>
                                        <li>Log Data and Cookies: We analyze log data and use cookies to understand user behavior, improve our website, and optimize marketing efforts. This information is aggregated and does not identify individual users.</li>
                                    </ol>

                                    <h2>Data Sharing and Security:</h2>
                                    <ol className="mb-5">
                                        <li>Third-Party Service Providers: We may engage trusted third-party service providers to assist us in operating our website or conducting business activities. These providers have access to your personal information only to perform specific tasks on our behalf and are obligated to protect its confidentiality.</li>
                                        <li>Legal Compliance: We may disclose your personal information if required by law, regulation, legal process, or governmental request to protect our rights, property, or safety, or the rights, property, or safety of others.</li>
                                        <li>Data Security: We implement reasonable security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                                    </ol>

                                    <h2>Updates to Privacy Policy:</h2>
                                    <p className="mb-5">We reserve the right to update this Privacy Policy from time to time. Any changes will be posted on our website, and the updated policy will be effective immediately upon posting.</p>
                                    <h2>Contact Us:</h2>
                                    <p className="mb-5">
                                    If you have any questions, concerns, or requests regarding our privacy practices or this
                                    Privacy Policy, please contact us at <a href="mailto:contact@adciasis.com">contact@adciasis.com</a>.
                                    <br />
                                    Last updated: 19th May 2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </>
    );
}
export default PrivacyPolicy;