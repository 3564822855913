import React from "react";
const MobileApp = (props) => {
	return (
		<>
			<section id="hero" className="mobile-app_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>
									Mobile App
									<br />
									Development
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section section-top">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Your Business, Your App</h2>
									<p className="text-left mb-5">In today's fast-paced world, having a mobile app can be a game-changer for businesses of all sizes. Being a prominent Mobile App Development Company, Adciasis Solutions understands the importance of having a mobile app for your business. Adciasis has been helping businesses and individuals by delivering powerful mobile apps that meet their needs and drive results.</p>
									<p className="mb-0">At Adciasis, you can hire mobile app developers with a proven track record of delivering outstanding mobile app solutions for a wide range of industries. We use the latest technologies and development frameworks to create mobile apps that are not only visually stunning but also highly functional, intuitive, and user-friendly.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="development-section">
							<div className="row row-flex">
								<div className="col-md-4 col-sm-4" data-aos="fade-up" data-aos-delay="0">
									<div className="card-plain">
										<div className="content">
											<div className="mb-4 dev-sec-icon-wrap">
												<img alt="UI/UX Development" className="" src="assets/icons/mobile-app/mobile-ui-design.svg" />
											</div>
											<h3>
												UI/UX <br /> Development
											</h3>
											<p className="text-left mb-5 mb-sm-0">Being a prominent <strong>UI/UX design company</strong>, Adciasis understands the importance of creating a visually appealing and user-friendly app. Our team of <strong>Top rated UI/UX Developers</strong> are experts in developing user interfaces and experiences that are intuitive, visually pleasing, and engaging. We take the time to understand your brand, business goals, and target audience, and then create designs that are tailored to your specific needs.</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-4" data-aos="fade-up" data-aos-delay="15">
									<div className="card-plain">
										<div className="content">
											<div className="mb-4 dev-sec-icon-wrap">
												<img alt="Native App Development" className="" src="assets/icons/mobile-app/native-app-development.svg" />
											</div>
											<h3>
												Native App <br /> Development
											</h3>
											<p className="text-left mb-5 mb-sm-0">We have a team of experienced developers who specialize in Native app development for both Android and iOS. Our developers use native programming languages like Kotlin and Swift to develop high-quality, robust apps that take full advantage of the platform's features and capabilities.</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-4" data-aos="fade-up" data-aos-delay="30">
									<div className="card-plain">
										<div className="content">
											<div className="mb-4 dev-sec-icon-wrap">
												<img alt="Hybrid App Development" className="" src="assets/icons/mobile-app/hybrid-app-development.svg" />
											</div>
											<h3>
												Hybrid App <br /> Development
											</h3>
											<p className="text-left mb-5 mb-sm-0">Our team has expertise in Hybrid app development, which allows us to create powerful apps using popular & trending frameworks like React Native, Flutter, HTML/CSS, and JavaScript. We utilize the latest technologies to develop apps that can be run on multiple platforms, including iOS and Android, with minimal development time and cost.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="one-stop_section">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-md-11" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
				<section className="section">
					<div className="container" data-aos="fade-up">
						<div className="development-process row justify-content-center">
							<div className="col-md-9 col-sm-9 pb-sm-4" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center mb-4">Development Process</h2>
								<p className="mt-sm-5 text-center">We follow a comprehensive development process to ensure that your app meets your requirements and exceeds your expectations. Our process includes the following steps:</p>
							</div>
							<div className="row d-flex" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/01-understand-the-requirements.svg" />
													</div>
													<h5>Discovering Your Vision</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>We start by understanding your business requirements, goals, target audience, and other relevant factors to create an app that aligns with your needs.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/02-wireframe-and-prototype.svg" />
													</div>
													<h5>Wireframe and Prototype</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Our designers create a rough layout of the app's interface and develop a prototype that outlines the app's functionalities.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/03-graphic-and-ui-designing.svg" />
													</div>
													<h5>UI/UX Design</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Hire UI/UX Designer from Adciasis for stunning mobile app designs. Our design team will develop a visually pleasing and user-friendly interface for the app.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/04-development-and-optimization.svg" />
													</div>
													<h5>Enhancement and Development</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Our developers will use the latest technologies and programming languages to build the app's backend and frontend, optimizing it for performance and scalability.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/05-quality-and-extension.svg" />
													</div>
													<h5>Quality And Extension</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>We test the app to ensure that it is free of bugs and glitches and meets your requirements. We then extend it with additional features and functionalities as needed.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="flip-card" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap">
														<img alt="" className="ic" src="assets/icons/mobile-app/development-process/06-first-draft-to-final-delivery.svg" />
													</div>
													<h5>First Draft to Final Delivery</h5>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Once the app is completed, we deliver it to you for testing and feedback. We then make any necessary changes and deliver the final version of the app to you.</p>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default MobileApp;
