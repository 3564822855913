import React from "react";

const Strategy = (props) => {
	return (
		<>
			<section id="hero" className="media-services_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>Media Services</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section section-top">
					<div className="container" data-aos="fade-up">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Importance of Media Services</h2>
									<p className="text-left mb-5">In today's digital age, businesses need to have a strong online presence to reach out to their target audience. With the rise of social media and digital channels, businesses need to create content that not only grabs attention but also drives engagement and conversions. Media services play a vital role in helping businesses achieve this goal. Media app development services enable businesses to create engaging content that can be shared across various digital platforms, including websites, social media, and mobile apps. This, in turn, helps businesses to increase their visibility, reach out to a larger audience, and drive more conversions.</p>
									<p className="text-left mb-5">At Adciasis, we're committed to delivering innovative app development solutions that help our clients stay ahead of the curve. Our media and entertainment app development services are designed to help you achieve your business goals, whether you're looking to increase engagement, drive revenue, or reduce costs.</p>
									<p className="mb-0">Our team is well-versed in a range of media software development technologies, including video editing software, music editing software, streaming software, and more. Whether you need a custom media software solution or a complex media management system, we've got you covered. What else? We also offer ongoing maintenance and support services to ensure that your media software remains up-to-date and relevant.</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section light-bg mt-5">
					<div className="container pb-5">
						<div className="media-service row justify-content-center">
							<div className="col-12 mb-5" data-aos="fade-up" data-aos-delay="0">
								<h2 className="mb-4 mt-4">Interactive Media</h2>
								<p className="mt-sm-5">Interactive media is an effective way to engage audiences and deliver information in a compelling way. At Adciasis Solutions, we specialize in creating interactive media that captivates and engages audiences. We offer a range of interactive media services, including interactive presentations and PowerPoint presentations. Our team of designers and developers use the latest tools and technologies to create interactive media that is visually stunning, informative, and easy to navigate.</p>
							</div>
							<div className="row d-flex" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-6 ps-sm-0 pe-sm-4">
									<div className="flip-card my-5" tabIndex="0">
										<div className="flip-card-inner my-5">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap mb-5">
														<img alt="Interactive Presentations" className="ic" src="assets/icons/media-service/interactive-presentations.svg" />
													</div>
													<h5>Interactive Presentations</h5>
													<p className="my-4">Interactive media is an effective way to engage audiences and deliver information in a compelling way. We specialize in creating interactive media that captivates and engages audiences.</p>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Interactive media is an effective way to engage audiences and deliver information in a compelling way. We specialize in creating interactive media that captivates and engages audiences.</p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-6 pe-sm-0 ps-sm-4">
									<div className="flip-card my-5" tabIndex="0">
										<div className="flip-card-inner my-5">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap mb-5">
														<img alt="" className="ic" src="assets/icons/media-service/powerpoint-presentations.svg" />
													</div>
													<h5>PowerPoint Presentations</h5>
													<p className="my-4">PowerPoint is a popular software program used for creating and delivering presentations. We use the latest design techniques and tools to create PowerPoint presentations that are Professional, engaging, and easy to navigate.</p>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>PowerPoint is a popular software program used for creating and delivering presentations. We use the latest design techniques and tools to create PowerPoint presentations that are Professional, engaging, and easy to navigate.</p>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section mt-5">
					<div className="container">
						<div className="media-service row justify-content-center">
							<div className="col-12 mb-5" data-aos="fade-up" data-aos-delay="0">
								<h2 className="mb-4">Branding Solutions</h2>
								<p className="mt-sm-5">Corporate branding and corporate brochures play a crucial role in establishing a business's brand identity. At Adciasis Solutions, we offer comprehensive branding solutions that help businesses create a strong brand presence in the digital world. Our branding solutions include corporate branding, where we help businesses create a unique brand identity that sets them apart from their competitors. We also offer corporate brochure design services that help businesses create visually appealing brochures that showcase their products and services.</p>
							</div>
							<div className="row d-flex" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-6 ps-sm-0 pe-sm-4">
									<div className="flip-card my-5" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap mb-5">
														<img alt="" className="ic" src="assets/icons/media-service/corporate-branding.svg" />
													</div>
													<h5>Corporate Branding</h5>
													<p className="my-4">Corporate branding is the process of creating a unique brand identity for a business that reflects its values, mission, and vision. We work closely with clients to understand their business goals and values, and create a unique brand identity that aligns with their vision. </p>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Corporate branding is the process of creating a unique brand identity for a business that reflects its values, mission, and vision. We work closely with clients to understand their business goals and values, and create a unique brand identity that aligns with their vision. </p>
											</div> */}
										</div>
									</div>
								</div>
								<div className="col-sm-6 pe-sm-0 ps-sm-4">
									<div className="flip-card my-5" tabIndex="0">
										<div className="flip-card-inner">
											<div className="flip-card-front">
												<div className=" text-center">
													<div className="icon-wrap mb-5">
														<img alt="" className="ic" src="assets/icons/media-service/corporate-brochures.svg" />
													</div>
													<h5>Corporate Brochures</h5>
													<p className="my-4">Corporate brochures are marketing materials that provide information about a business's products or services. We offer corporate brochure design services that help businesses create visually appealing brochures that highlight their products and services. </p>
												</div>
											</div>
											{/* <div className="flip-card-back">
												<p>Corporate brochures are marketing materials that provide information about a business's products or services. We offer corporate brochure design services that help businesses create visually appealing brochures that highlight their products and services. </p>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="one-stop_section mb-0">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-md-11" data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};
export default Strategy;
