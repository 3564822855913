import React from "react"
import Header from "./Header"
import Footer from "./Footer"

const Layout= (props)=> {
    return (
        <React.Fragment>
            <Header/>
            <div className="main-content">{props.children}</div>
            <Footer />
            {/* <div id="preloader"></div> */}
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </React.Fragment>
    )
}

export default Layout;
