import React from "react";

const EnterpriseApplications = (props) => {
	return (
		<>
			<section id="hero" className="enterprise-app_banner otherpage-hero">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-7 align-self-center">
							<div className="hero-container" data-aos="fade-left" data-aos-delay="0">
								<h1>
									Enterprise
									<br />
									Application
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id="main">
				<section className="section section-top">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="0">
								<div className="">
									<h2>Importance of Enterprise Application</h2>
									<p className="text-left mb-5">In today's fast-paced business environment, enterprise application development is crucial for companies to stay competitive and efficient. Enterprise applications help organizations automate their processes, improve communication and collaboration among teams, and make informed decisions based on real-time data. With the right enterprise application in place, companies can streamline their operations, reduce costs, and drive growth.</p>
									<p className="text-left mb-5">At Adciasis Solutions, we use the latest technologies and development methodologies to create enterprise applications that are scalable, secure, and efficient. Our team is well-versed in a range of enterprise application development technologies, including .NET, Java, and PHP. We also offer ongoing maintenance and support services to ensure that your enterprise application remains up-to-date and relevant.</p>
									<p className="mb-0">With our enterprise application development services, you can improve your business processes, automate your operations, and gain valuable insights into your business operations. Contact us today to learn more about how our enterprise application development services can help your business grow and succeed.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div className="container">
					<div className="my-5">
						<hr />
					</div>
				</div>
				<section className="section">
					<div className="container" data-aos="fade-up">
						<div className="development-process">
							<div className="col-md-12 col-sm-12" data-aos="fade-up" data-aos-delay="0">
								<h2 className="mb-5">Our Enterprise App Development Expertise</h2>
							</div>
							<div className="row d-flex title-h5" data-aos="fade-up" data-aos-delay="0">
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/tailored-web-applications.svg" />
										</div>
										<h4 className="bold px-3">Tailored Web Applications</h4>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/streamlined-resource-management.svg" />
										</div>
										<h4 className="bold">Streamlined Resource Management</h4>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/seamless-application-migration.svg" />
										</div>
										<h4 className="bold">Seamless Application Migration</h4>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/high-quality-coding-services.svg" />
										</div>
										<h4 className="bold">High-quality Coding Services</h4>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/flexible-dedicated-developers.svg" />
										</div>
										<h4 className="bold">Flexible Dedicated Developers</h4>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="card text-center px-4 py-5">
										<div className="">
											<img alt="" className="ic" src="assets/icons/enterprise-app/proactive-maintenance-and-support.svg" />
										</div>
										<h4 className="bold">
											Proactive Maintenance <br /> & Support
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="one-stop_section my-0">
					<div className="container" data-aos="fade-up">
						<div className="row justify-content-center">
							<div className="col-md-11 " data-aos="fade-up" data-aos-delay="0">
								<h2 className="text-center">Discover Efficiency & Profitability with our Smart Solutions</h2>
								<h5 className="text-center">Let our incredible team assist you in unlocking your digital needs.</h5>
								<a className="getstarted" href="/contact-us">
									Contact Us
								</a>
							</div>
						</div>
					</div>
				</div>
				<section className="section py-0">
					<div className="tech-logo-section">
						<div className="container py-5" data-aos="fade-up" data-aos-delay="0">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<h2 className="pe-sm-5 text-center pt-5">Technologies We Use</h2>
								</div>
								<div className="col-md-12 col-sm-12 align-items-stretch">
									<div className="">
										<ul className="ic-tech-list mt-0">
											<li className="">
												<img alt="iOS" src="assets/img/tech/ios.png" />
											</li>
											<li className="">
												<img alt="Android" src="assets/img/tech/android.png" />
											</li>
											<li className="">
												<img alt="Python" src="assets/img/tech/python.png" />
											</li>
											<li className="">
												<img alt="MongoDB" src="assets/img/tech/mongoDB.png" />
											</li>
											<li className="">
												<img alt="Java Script" src="assets/img/tech/java-script.png" />
											</li>
											<li className="">
												<img alt="Jquery" src="assets/img/tech/jquery.png" />
											</li>
											<li className="">
												<img alt="Node JS" src="assets/img/tech/node.png" />
											</li>
											<li className="">
												<img alt="Angular JS" src="assets/img/tech/angular.png" />
											</li>
											<li className="">
												<img alt="React" src="assets/img/tech/react.png" />
											</li>
											<li className="">
												<img alt="PHP" src="assets/img/tech/PHP.png" />
											</li>
											<li className="">
												<img alt="Java" src="assets/img/tech/java.png" />
											</li>
											<li className="">
												<img alt="C-Hash" src="assets/img/tech/C-hash.png" />
											</li>
											<li className="">
												<img alt=".Net" src="assets/img/tech/dotnet.png" />
											</li>
											<li className="">
												<img alt="CSS HTML" src="assets/img/tech/html-css.png" />
											</li>
											<li className="">
												<img alt="Swift" src="assets/img/tech/swift.png" />
											</li>
											<li className="">
												<img alt="Object-C" src="assets/img/tech/Object-C.png" />
											</li>
											<li className="">
												<img alt="Kotlin" src="assets/img/tech/kotlin.png" />
											</li>
											<li className="">
												<img alt="Flutter" src="assets/img/tech/flutter.png" />
											</li>
											<li className="">
												<img alt="React Native" src="assets/img/tech/react-native.png" />
											</li>
											<li className="">
												<img alt="Magento" src="assets/img/tech/magento.png" />
											</li>
											<li className="">
												<img alt="MySQL" src="assets/img/tech/mysql.png" />
											</li>
											<li className="">
												<img alt="PostgreSQL" src="assets/img/tech/PostgreSQL.png" />
											</li>
											<li className="">
												<img alt="Wordpress" src="assets/img/tech/wordpress.png" />
											</li>
											<li className="">
												<img alt="Laravel" src="assets/img/tech/laravel.png" />
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};
export default EnterpriseApplications;
